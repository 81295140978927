<template>
  <screen-split-view v-if="!isLoading">
    <template v-slot:leftSide>
      <staff-request-form :data="getHiringRequest" @toggleJobSpecModal="toggleJobSpecModal(true)"/>
    </template>
    <!-- HALF WAY POINT -->
    <template v-slot:rightSide>
      <div class="pointer-events-none cursor-not-allowed opacity-60">
        <!-- Request Form -->
        <div class="p-2">
          <h1 class="font-bold">Employment Offer</h1>
          <p class="">To: Mark the Manager</p>
        </div>
        <!-- Job Spec / Salary Info / Rota Info -->
        <div class="m-auto w-11.5/12 mb-2">
          <div class="flex justify-between items-center">
            <label for="status">Reason for action...</label>
          </div>
          <drop-down
            selectName="status"
            v-model="payload.status"
            :values="dropDownOptions"
            :onChange="resolveOnChangeDecision"
          />
        </div>
        <!-- Icon / Request Info -->
        <div class="flex w-full mb-2">
          <div class="text-center text-grey bg-white rounded-xl ml-2 shadow-md">
            <p class="text-grey font-semibold text-lg mx-3">
              {{ getHiringRequest.contract_type.toUpperCase() }}
            </p>
            <div class="relative">
              <span class="material-icons text-7xl mx-3" :class="iconClass">
                person
              </span>
            </div>
          </div>
          <div
            class="
              bg-white
              rounded-xl
              w-full
              flex flex-col
              mx-2
              justify-around
              shadow-md
            "
          >
            <h3 class="font-bold text-lg pl-2">Request Information</h3>
            <div class="flex pl-2">
              <p>
                Job title:
                <span>{{
                  capitalizeFirstLetter(getHiringRequest.job_title)
                }}</span>
              </p>
            </div>
            <div class="flex pl-2">
              <p>
                Reports to:
                {{ capitalizeFirstLetter(getHiringRequest.reporting_to) }}
              </p>
            </div>
          </div>
        </div>
        <div class="bg-white rounded-xl m-2 pb-2 shadow-md">
          <div class="justify-center items-center flex space-x-3 m-3">
            <!-- Education Experience -->
            <education-information
              :getEducationInformation="educationInformationData"
              :hideEditButton="true"
            />
            <!-- Experience Experience -->
            <experience-information
              :getExperiencesInformation="experienceInformationData"
              :hideEditButton="true"
            />
          </div>
        </div>
        <div class="bg-white rounded-xl m-2 pb-2 flex flex-col shadow-md">
          <h3 class="font-bold m-2 text-lg">Decision Reasoning</h3>

          <div class="w-11.5/12 m-auto">
            <QuillEditor
              style="min-height: 250px"
              toolbar="essential"
              theme="snow"
              content-type="html"
              v-model:content="payload.decision_comment"
            />
          </div>
          <!-- <div
          v-else
          class="border border-grey rounded-l mx-2 flex-grow h-60 overflow-scroll"
        >
          <p class="ml-2">
            <span v-html="getHiringRequest.comment"></span>
          </p>
        </div> -->
        </div>
        <div class="flex justify-center m-2">
          <base-button @click="showConfirmationDialog">Submit</base-button>
        </div>
        <base-confirmation-dialog
          :show="confirmationDialog"
          title="Are you sure?"
        >
          <base-button @click="confirmHiringRequest">Submit</base-button>
          <base-button :mode="'cancel'" @click="closeConfirmationDialog"
            >Cancel</base-button
          >
        </base-confirmation-dialog>
      </div>
    </template>
  </screen-split-view>
<job-person-spec-modal
  @closeModal="toggleJobSpecModal(false)"
  v-if="jobSpecModalOpen"
  :jobSpecification="getHiringRequest.job_specification"
  :personSpecification="getHiringRequest.person_specification"
/>
</template>

<script>
import { markRaw } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";
import customPaginate from "@/components/ui/baseComponents/paginate.vue";
import dropDown from "@/components/ui/forms/dropDown.vue";
import experienceInformation from "@/components/layout/profile/tabs/employmentHR/experienceInformation.vue";
import educationInformation from "@/components/layout/profile/tabs/employmentHR/educationInformation.vue";
import baseConfirmationDialog from "@/components/ui/baseComponents/baseConfirmationDialog.vue";
import staffRequestForm from "@/components/ui/baseComponents/Recruitment/staffRequestForm.vue";
import screenSplitView from "@/components/ui/baseComponents/screenSplitView.vue";
import jobPersonSpecModal from "@/components/modal/jobPersonSpecModal.vue";
export default {
  components: {
    QuillEditor,
    dropDown,
    experienceInformation,
    educationInformation,
    baseConfirmationDialog,
    staffRequestForm,
    screenSplitView,
    jobPersonSpecModal,
  },
  async created() {
    this.isLoading = true;
    await this.fetchHiringRequest(this.$route.params.offerId);
    this.isLoading = false;
  },
  data() {
    return {
      jobSpecModalOpen: false,
      payload: {
        hiring_request: this.$route.params.staffRequestForm,
        status: null,
        progress: "declined",
        decision_reason: null,
        decision_comment: "",
      },
      formIsLoading: false,
      isLoading: false,
      singleDeptIsLoading: false,
      page: 1,
      options: {
        template: markRaw(customPaginate),
      },
      dropDownOptions: {
        accept: "Accept",
        ammend: "Ammend",
        declined: "Declined",
      },
      experienceInformationData: [
        {
          id: 1,
          job_title: "Primary Doctor ",
          type_of_business: "Fake Practice",
          job_start_date: "2011-10-05T14:48:00.000Z",
          job_end_date: "2018-02-09T16:08:18+0000",
        },
        {
          id: 2,
          job_title: "Secondary Doctor ",
          type_of_business: "Fake Practice",
          job_start_date: "2011-10-05T14:48:00.000Z",
          job_end_date: "2018-02-09T16:08:18+0000",
        },
        {
          id: 3,
          job_title: "Trainee Doctor ",
          type_of_business: "Fake Practice",
          job_start_date: "2011-10-05T14:48:00.000Z",
          job_end_date: "2018-02-09T16:08:18+0000",
        },
      ],
      educationInformationData: [
        {
          id: 1,
          subject: "Primary Doctor ",
          institution: "Fake Practice",
          start_date: "2011-10-05T14:48:00.000Z",
          completion_date: "2018-02-09T16:08:18+0000",
          certificate: "https://imgur.com/gallery/VWjRf",
        },
        {
          id: 1,
          subject: "Primary Doctor ",
          institution: "Fake Practice",
          start_date: "2011-10-05T14:48:00.000Z",
          completion_date: "2018-02-09T16:08:18+0000",
          certificate: "https://imgur.com/gallery/KWvtdg0",
        },
        {
          id: 1,
          subject: "Primary Doctor ",
          institution: "Fake Practice",
          start_date: "2011-10-05T14:48:00.000Z",
          completion_date: "2018-02-09T16:08:18+0000",
          certificate: "https://imgur.com/gallery/LbPsLrC",
        },
      ],

      reasonForRecruitment: null,
      confirmationDialog: false,
    };
  },

  methods: {
    capitalizeFirstLetter(string) {
      return string.charAt(0).toUpperCase() + string.slice(1);
    },
    async fetchHiringRequest(requestFormId) {
      try {
        // this.formIsLoading = true;
        await this.$store.dispatch("HQnewHire/fetchHiringRequest", {
          hiring_request: requestFormId,
        });
        if (this.getHiringRequest && this.getHiringRequest.status) {
          this.payload.status = this.getHiringRequest.status;
        }
        if (this.getHiringRequest && this.getHiringRequest.decision_reason) {
          this.payload.decision_reason = this.getHiringRequest.decision_reason;
        }
        // this.formIsLoading = false;
      } catch (error) {
        // this.$router.push({
        //   name: "hq-recruitment",
        //   query: { error: "true" },
        // });
        this.error = error.message || "Something went wrong!";
      }
    },

    myCallback(e) {
      console.log(e);
    },
    resolveOnChangeDecision(data) {
      this.payload.status = data.target.value;
    },
    resolveOnChangeReason(data) {
      this.payload.decision_reason = data.target.value;
    },
    showConfirmationDialog() {
      this.confirmationDialog = true;
    },
    closeConfirmationDialog() {
      this.confirmationDialog = false;
    },
    async confirmHiringRequest() {
      //   try {
      //     await this.$store.dispatch(
      //       "HQnewHire/processHiringRequest",
      //       this.payload
      //     );
      //     this.$router.push({
      //       name: "hq-recruitment",
      //       query: { success: "true" },
      //     });
      //   } catch (error) {
      //     this.error = error.message || "Something went wrong!";
      //     this.$router.push({
      //       name: "hq-recruitment",
      //       query: { error: "true" },
      //     });
      //   }
      console.log("SUBMITTED");
      this.$router.push({
        name: "hq-candidate-offers",
        query: { success: "true" },
      });
    },
    toggleJobSpecModal(value) {
      this.jobSpecModalOpen = value;
    },
  },
  computed: {
    getHiringRequest() {
      return this.$store.getters["HQnewHire/getHiringRequest"];
    },

    iconClass() {
      return {
        "text-teal": this.getHiringRequest.contract_type === "Permanent",
        "text-orange": this.getHiringRequest.contract_type === "Casual",
        "text-green": this.getHiringRequest.contract_type === "Fixed-term",
        "text-red": this.getHiringRequest.contract_type === "Zero-hour",
      };
    },
  },
};
</script>

<style scoped>
#forHeight {
  height: calc(100vh - 70px);
}

.flip-content {
  transform-style: preserve-3d;
}
.flip:hover .flip-content {
  transform: rotateY(180deg);
  transition: transform 0.3s;
}
.flip-front,
.flip-back {
  position: absolute;

  backface-visibility: hidden;
}
.flip-back {
  transform: rotateY(180deg);
}
.recent-activity .res-activity-list li:before {
  content: "";
  position: absolute;
  border-radius: 100%;
}

.recent-activity .res-activity-list li:before {
  width: 10px;
  height: 10px;
  left: -32px;
  top: 5px;
  border: 2px solid #ed8b00;
  margin-right: 15px;
  z-index: 2;
  background: #fff;
}
.recent-activity .res-activity-list li:hover:before {
  border: 4px solid #ed8b00;
  cursor: pointer;
}
.recent-activity .res-activity-list:after {
  content: "";
  border: 1px solid #e8edee;
  position: absolute;
  top: 5px;
  bottom: 0;
  left: 4px;
}
</style>
